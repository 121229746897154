import { z } from "zod";

import { GEOX_ENV, NODE_ENV } from "./consts";

function createEnv() {
  const envSchema = z.object({
    NODE_ENV: z.enum([NODE_ENV.PRODUCTION, NODE_ENV.DEVELOPMENT, NODE_ENV.TEST]).default(process.env.NODE_ENV),
    GEOX_ENV: z.string().default(GEOX_ENV.DEVELOPMENT),
    TEST_ENV: z.string().default(""),
    GOOGLE_MAPS_API_KEY: z.string(),
    MAPBOX_TOKEN: z.string(),
    FF_HIDE_USDB_PAGE: z.string().default("false"),
    LINKEDIN_CLIENT_ID: z.string().default("78vr51gnd5o29w"),
    GOOGLE_CLIENT_ID: z.string().default("1077433431721-f806riujvmd94hgq4veujs1pnekcld30.apps.googleusercontent.com"),
    GOOGLE_API_KEY: z.string().default("5_wnqOuzezPKSTcFQAA_o0i2"),
    MIXPANEL_TOKEN: z.string(),
    APM_SERVER_URL: z.string(),
    CALENDLY_LINK_PATH: z.string().default("geox-test"),
  });

  const envVars = Object.entries(process.env).reduce((acc, [key, value]) => {
    if (key === "NODE_ENV") {
      acc["NODE_ENV"] = value;
    } else if (key.startsWith("REACT_APP_")) {
      acc[key.replace("REACT_APP_", "")] = value;
    }
    return acc;
  }, {});

  const parsedEnv = envSchema.safeParse(envVars);

  if (!parsedEnv.success) {
    throw new Error(
      `Invalid env provided. The following variables are missing or invalid:\n` +
        `${Object.entries(parsedEnv.error.flatten().fieldErrors)
          .map(([key, value]) => `- ${key}: ${value}`)
          .join("\n")}`
    );
  }

  return parsedEnv.data;
}

export const env = createEnv();
